<template>
  <b-card>
    <div v-if="isLoading" class="mb-5">
      <b-spinner
        type="grow"
        small
        variant="primary"
        class="mx-auto mt-3 d-block"
      />
    </div>
    <div v-else>
      <div class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <!-- <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a> -->
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t("tasks.title") }}
          </h3>
        </div>
        <!-- Download -->
        <b-button
          v-if="isStaff"
          class="float-right mr-1 p-0"
          variant="link"
          @click="downloadTasks"
        >
          <feather-icon icon="DownloadCloudIcon" variant="primary" size="20" />
        </b-button>
        <!-- Add -->
        <b-button
          v-if="isStaff || canAdd"
          v-b-modal.modal-create-tasks
          class="float-right mr-1 p-0"
          variant="link"
        >
          <feather-icon icon="PlusIcon" variant="primary" size="20" />
        </b-button>
      </div>
      <div v-if="wiTasks && wiTasks.length > 0">
        <b-row class="todo-app-list mt-3">
          <b-col>
            <b-table
              class="position-relative"
              :items="wiTasks"
              responsive
              :fields="fields"
              primary-key="id"
              show-empty
              sort-icon-left
              empty-text="loading"
            >
              <!-- Column: Title -->
              <template #cell(title)="{ item }">
                <b-media class="d-flex align-items-center">
                  <b-link
                    :to="getSuggestionLocation(item)"
                    class="text-dark d-flex  mt-50"
                  >
                    <p class="mb-0 ml-1 pl-25 truncated-text">
                      {{ item.title }}
                    </p>
                  </b-link>
                </b-media>
              </template>
              <!-- Column: Task -->
              <template #cell(task)="{ item }">
                <b-link
                  :to="getSuggestionLocation(item)"
                  class="text-dark d-flex"
                >
                  <div>
                    <p class="mb-0 ml-1 pl-25 truncated-text">
                      {{ item.message }}
                    </p>
                  </div>
                </b-link>
              </template>

              <!-- Column: User -->
              <template #cell(user)="{ item }">
                <b-link
                  :to="getSuggestionLocation(item)"
                  class="text-dark d-flex"
                >
                  <div v-if="item.assignee" class="text-nowrap">
                    <p class="mb-0 ml-1 pl-25">
                      <b-avatar
                        v-b-tooltip.hover
                        :title="`${item.assignee.name} ${item.assignee.surname} `"
                        size="32"
                        :src="getImageSrc(item.assignee.avatarURL)"
                        class="mr-50"
                        style="border-style: groove; border-color: grey; border-width: 2px;"
                      />
                      {{ item.assignee.name }}
                      {{ item.assignee.surname }}
                    </p>
                  </div>
                  <span v-else class="text-muted ml-1 pl-25 mt-50">---</span>
                </b-link>
              </template>
              <!-- Column: Priority -->
              <template #cell(priority)="{ item }">
                <b-link
                  :to="getSuggestionLocation(item)"
                  class="text-dark d-flex"
                >
                  <b-icon
                    v-b-modal.modal-edit-priority
                    icon="flag-fill"
                    scale="1.3"
                    :variant="resolveTagVariant(item.priority)"
                    class="mt-1 cursor-pointer ml-2"
                    style="outline-style: none;"
                  />
                  <span class="mt-50 ml-50"><small>{{ getTranslatedPriority(item.priority) }}</small></span>
                </b-link>
              </template>
              <!-- Column: Status -->
              <template #cell(status)="{ item }">
                <b-link
                  :to="getSuggestionLocation(item)"
                  class="text-dark d-flex mt-50"
                >
                  <div class="text-nowrap">
                    <p class="mb-0">
                      <b-badge
                        pill
                        :variant="`${resolveStatusVariant(item.status)}`"
                        class="text-capitalize ml-1"
                      >
                        {{ getStatus(item.status) }}
                      </b-badge>
                    </p>
                  </div>
                </b-link>
              </template>
              <!-- Column: OwnedByMember -->
              <template #cell(creator)="{ item }">
                <b-link
                  :to="getSuggestionLocation(item)"
                  class="text-dark d-flex mt-50"
                >
                  <span v-if="item.ownedByMember" class="ml-2">{{ item.ownedByMember.name }} {{ item.ownedByMember.surname }}</span>
                  <span v-else class="text-muted ml-2">---</span>
                </b-link>
              </template>
              <!-- Column: Due date -->
              <template #cell(date)="{ item }">
                <b-link
                  :to="getSuggestionLocation(item)"
                  class="text-dark d-flex mt-50"
                >
                  <span v-if="item.dueDate" class="ml-2">{{ new Date(item.dueDate).toLocaleDateString() }}</span>
                  <span v-else class="text-muted ml-2">---</span>
                </b-link>
              </template>
              <!-- Column:  Attach -->
              <template #cell(attach)="{ item }">
                <b-link
                  :to="getSuggestionLocation(item)"
                  class="text-dark d-flex mt-50"
                >
                  <feather-icon
                    v-if="item.attachments && item.attachments.length > 0"
                    icon="PaperclipIcon"
                    size="16"
                    class="text-muted"
                  />
                </b-link>
              </template>
            </b-table>
            <div class="d-flex justify-content-center">
              <b-pagination
                v-model="page"
                :total-rows="total"
                :per-page="30"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row v-else class="horizontal-placeholder flex-column">
        <b-col cols="12">
          <img :src="placeholder" class="m-0 d-block mx-auto">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{
              $t("backoffice.feedback.placeholder-text", {
                item: $t("tasks.title"),
              })
            }}
          </p>
        </b-col>
      </b-row>
    </div>
    <!-- Create Taks -->
    <b-modal
      id="modal-create-tasks"
      :title="$t('tasks.create.title')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      :ok-disabled="!projectInput.title"
      size="lg"
      @hidden="reset"
      @ok="handleCreate"
    >
      <b-form>
        <b-form-group>
          <label for="name"> {{ $t("form-create-item.title") }}*:</label>
          <b-form-input
            id="name"
            v-model="projectInput.title"
            :placeholder="$t('form.type-placeholder')"
            type="text"
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group class="flex-grow-1">
              <label for="suggestionType" class="font-weight-bold">
                {{ $t("tasks.status.assignee") }}:</label>
              <members-select
                v-model="assignee"
                class="style-chooser"
                dir="ltr"
                :multiple="false"
                :clearable="false"
                :placeholder="$t('members.search-placeholder')"
                searchable
                :fetch-data="fetchMembers"
                label="name"
              >
                <template #option="option">
                  <b-avatar size="sm" :src="option.avatarURL" />
                  <span
                    class="ml-50 align-middle"
                  >{{ option.name }} {{ option.surname }}</span>
                </template>
                <template #selected-option="option">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="option.avatarURL"
                  />
                  <span
                    class="ml-50 align-middle"
                  >{{ option.name }} {{ option.surname }}</span>
                </template>
              </members-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="flex-grow-1">
              <label for="suggestionType" class="font-weight-bold">
                {{ $t("tasks.status.status") }}:</label>
              <!-- <v-select
                v-model="type"
                name="suggestionType"
                :searchable="false"
                :clearable="false"
                :options="typeOptions"
              /> -->
              <v-select
                v-model="type"
                name="suggestionType"
                :searchable="false"
                label="name"
                :clearable="false"
                :options="typeOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label for="dueDate" class="font-weight-bold">
              {{ $t("tasks.status.due-date") }}:</label>
            <VueCtkDateTimePicker
              id="dueDate"
              v-model="dueDate"
              :locale="locale"
              format="YYYY-MM-DD"
              formatted="ll"
              :label="$t('backoffice.availability.date-no-slots')"
              color="var(--primary)"
              no-button-now
              only-date
              button-color="var(--primary)"
            />
          </b-col>
          <b-col>
            <b-form-group class="flex-grow-1">
              <label for="priority" class="font-weight-bold">
                {{ $t("tasks.status.priority") }}:</label>
              <v-select
                v-model="priority"
                name="priority"
                :placeholder="$t('form.select-placeholder')"
                :searchable="false"
                :clearable="false"
                :options="priorityOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <label class="mt-2" for="description">{{ $t("form-create-item.description") }}:</label>
          <quill-editor v-model="projectInput.description" />
        </b-form-group>
      </b-form>
    </b-modal>
    <loading-modal v-model="isDownloading" v-ripple.400="'rgba(234, 84, 85, 0.15)'" />
  </b-card>
</template>
<script>
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { VBTooltip } from 'bootstrap-vue';
import { getImageResource } from '@/@core/utils/image-utils';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { AppIDLUT } from '@copernicsw/community-common';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { quillEditor } from 'vue-quill-editor';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import FileUpload from '@core/components/files/FileUpload.vue';
import vSelect from 'vue-select';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
import LoadingModal from '@core/components/modal/LoadingModal.vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'TasksList',
  components: {
    quillEditor,
    vSelect,
    MembersSelect,
    LoadingModal,
  },
  directives: { BTooltip: VBTooltip, Ripple },

  data() {
    return {
      isLoading: false,
      projectInput: {},
      imageProject: null,
      imageSrc: null,
      perPage: 20,
      page: 1,
      dueDate: null,
      status: '',
      priority: '',
      assignee: '',
      type: '',
      attach: null,
      isDownloading: false,
      tasks: [],
      customNames: [],
    };
  },
  computed: {
    classifiers() {
      if (this.$store.getters.classifiers.tasks) {
        return this.$store.getters.classifiers.tasks.unpaginated;
      }
      return [];
    },
    typeOptions() {
      return [
        { name: this.$t("backoffice.feedback.status.to-do"), code: 42615 },
        { name: this.$t("backoffice.feedback.status.in-progress"), code: 42616 },
        { name: this.$t("backoffice.feedback.status.done"), code: 42617 },
        { name: this.$t("backoffice.feedback.status.rejected"), code: 42618 },
      ];
    },
    priorityOptions() {
      return [
        { label: this.$t('tasks.priority.urgent'), code: 1 },
        { label: this.$t('tasks.priority.high'), code: 2 },
        { label: this.$t('tasks.priority.normal'), code: 3 },
        { label: this.$t('tasks.priority.low'), code: 4 },
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    total() {
      return this.$store.getters.tasks?.meta?.total;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'task',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    fields() {
      return [
        {
          key: 'title',
          label: this.$t('tasks.name'),
          sortable: true,
        },
        {
          key: 'user',
          label: this.$t('tasks.status.assignee'),
        },
        {
          key: 'priority',
          label: this.$t('tasks.status.priority'),
        },
        {
          key: 'status',
          label: this.$t('backoffice.feedback.status-title'),
        },
        {
          key: 'creator',
          label: this.$t('contacts.creator'),
        },
        {
          key: 'date',
          label: this.$t('tasks.status.due-date'),
        },
        {
          key: 'attach',
          label: '',
        },
      ];
    },
    wiTasks() {
      return this.$store.getters.tasks.unpaginated;
    },
    placeholder() {
      return Placeholder;
    },
    item() {
      return this.$t('backoffice.feedback.title');
    },
    appId() {
      return 2;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
  },
  watch: {
    page(page) {
      this.fetchData(page);
    },
  },
  async created() {
    await this.fetchData(this.page);
    this.type = this.typeOptions[0];
    await this.fetchClassifiers()
    this.createClassifiersColumns()
    this.createFieldsColumns()
    // await this.getTypes();
  },
  methods: {
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        itemType: 'classifiers',
        storedKey: 'tasks',
        page: 1,
        requestConfig: { morphType: 'task', count: 1000 },
      });
    },
    createClassifiersColumns() {
      if (this.classifiers?.length > 0) {
        let typeNames = this.classifiers.map((item) => item.typeName);
        typeNames = [...new Set(typeNames)];
        this.dataClassifiers();
        return typeNames.map((item, index) => {
          this.fields.splice(4, 0, {
            key: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            label: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            sortable: false,
            thClass: 'pl-50',
            tdAttr: (value, key, it) => {
              let style = '';
              it.classifiers.map(x => {
                if ((x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0]) == (item[this.locale] ? item[this.locale] : Object.values(item)[0])) {
                  style = { style: `color: ${x.backgroundColor !== '#ffffff' ? x.backgroundColor : x.fontColor }; font-weight: 600` }
                }
              });
              return style;
            },
            formatter: (value) => {
              const allItems = [];
              if (value && Array.isArray(value)) {
                value?.map((item) => {
                  if (typeof item === 'object') {
                    allItems.push(item[this.locale] ? item[this.locale] : Object.values(item)[0]);
                  } else {
                    allItems.push(item);
                  }
                });
                return allItems.join(', ');
              }
              return value;
            },
          });
        });
      }
    },
    createFieldsColumns() {
      this.tasks.map((item) => {
        item.custom?.map((i) => {
          const field = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.customNames.push(field);
        });
      });
      this.dataFields();
      this.customNames = [...new Set(this.customNames)];
      this.customNames.map((item, index) => {
        this.fields.splice(4, 0, {
          key: item,
          label: item,
          sortable: false,
          thClass: 'pl-50',
          formatter: (value) => {
            if (typeof value === 'boolean') {
              if (value === true) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.true',
                );
              }
              if (value === false) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.false',
                );
              }
            }
            console.log(value)
            return value;
          },
        });
      });
    },
    dataClassifiers() {
      this.tasks = this.tasks.map((item) => item);
      this.tasks.map((n, index) => {
        n.classifiers.map((i) => {
          this.classifiers.map((x, p) => {
            if (i.key === x.key) {
              const typeName = x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0];
              if (Array.isArray(this.tasks[index][typeName])) {
                if (this.tasks[index][typeName].includes(i.name)) {
                  return;
                }
                this.tasks[index][typeName].push(i.name);
              } else {
                this.tasks[index][typeName] = [];
                this.tasks[index][typeName].push(i.name);
              }
            }
          });
        });
      });
    },
    dataFields() {
      this.tasks.map((n, index) => {
        n?.custom?.map((i) => {
          const typeName = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.tasks[index][typeName] = i.value;
        });
      });
    },
    async downloadTasks() {
      this.isDownloading = true;
      let response = '';
      response = await this.$store.dispatch('exportFiles', {
        itemType: 'tasks/export',
      });
      setTimeout(this.csv(response), 4000);
      this.isDownloading = false;
    },
    csv(response) {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = response.data.file;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Organizations.csv';
      hiddenElement.click();
    },
    reset() {
      this.attach = null;
      this.projectInput = {};
      this.dueDate = null;
      this.status = '';
      this.priority = '';
      this.assignee = '';
      this.type = '';
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    getTranslatedPriority(item) {
      const priorityLabel = this.priorityOptions.filter((priority) => priority.code === item)[0]?.label;
      if(!priorityLabel){
        return '---';
      }
      return priorityLabel;
    },
    async fetchMembers(page, searchString = '') {
      return this.$store.dispatch('getItems', {
        itemType: 'communityMembers',
        searchString,
        forceAPICall: true,
        page,
        perPage: 16,
        ...(this.$store.getters.currentCollective?.parentCollective?.slug && this.$store.getters.currentCollective?.isSection ? { communitySlug: this.$store.getters.currentCollective?.parentCollective?.slug } : ''),
        requestConfig: {
          getMinimalData: 1,
        },
      });
    },
    async handleCreate() {
      if (!this.projectInput.title) {
        return;
      }
      try {
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'tasks',
            requestConfig: {
              title: this.projectInput.title,
              description: this.projectInput?.description,
              dueDate: new Date(this.dueDate),
              assignee: this.assignee?.key,
              priorityID: this.priority?.code,
              statusID: this.type?.code,
            },
          },
          file: this.attach,
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('tasks.messages.create-success'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.tasks = this.tasks.map((item) => item);
        this.projectInput = {};
        this.status = '';
        this.priority = '';
        this.assignee = '';
        this.type = '';
        this.imageProject = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('tasks.messages.create-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString('en-GB');
    },
    async fetchData(page) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'tasks',
        page,
        perPage: 30,
        forceAPICall: true,
      });
      this.isLoading = false;
      this.tasks = this.tasks?.map((item) => item);
      this.updateBreadcrumbs();
    },
    getSuggestionLocation(task) {
      return {
        name: 'task-details',
        params: { id: task.key, communityId: this.$route.params.communityId },
      };
    },
    resolveStatusVariant(status) {
      if (status === 'To do' || status === null) {
        return 'info';
      }
      if (status === 'In review') {
        return 'muted';
      }
      if (status === 'In progress') {
        return 'warning';
      }
      if (status === 'Rejected') {
        return 'danger';
      }
      return 'success';
    },
    resolveTagVariant(tag) {
      if (tag === 1) {
        return 'danger';
      }
      if (tag === 2) {
        return 'warning';
      }
      if (tag === 3) {
        return 'info';
      }
      if (tag === 4) {
        return 'muted';
      }
      return 'light';
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    getStatus(status) {
      const statusTranslated = {
        'To do': this.$t('backoffice.feedback.status.to-do'),
        null: this.$t('backoffice.feedback.status.to-do'),
        'In progress': this.$t('backoffice.feedback.status.in-progress'),
        'In review': this.$t('backoffice.feedback.status.in-review'),
        'Rejected': this.$t('backoffice.feedback.status.rejected'),
        Done: this.$t('backoffice.feedback.status.done'),
      };
      return statusTranslated[status];
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t('tasks.title'),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    getImageResource,
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
.truncated-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 550px;
  min-width: 0;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
